const setLanguageToBrowserLanguage = {
  methods: {
    setLanguageToBrowserLanguage() {
      const userLangBrowserString = navigator.language || navigator.userLanguage;
      const userLang = (userLangBrowserString.indexOf('-') !== -1)
        ? userLangBrowserString.substring(0, userLangBrowserString.indexOf('-'))
        : userLangBrowserString;
      if (userLang === 'de') {
        this.$root.$i18n.locale = 'de-DE';
      } else {
        this.$root.$i18n.locale = 'en-US';
      }
    },
  },
};

export default setLanguageToBrowserLanguage;
