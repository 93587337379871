var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "content-wrapper" } }, [
    _c(
      "div",
      { attrs: { id: "content" } },
      [
        _c(
          "b-container",
          { staticClass: "b-card-forms" },
          [
            _c(
              "b-row",
              { staticClass: "vh-100", attrs: { "align-h": "center" } },
              [
                _c(
                  "b-col",
                  {
                    attrs: {
                      "align-self": "center",
                      cols: "12",
                      sm: "9",
                      lg: "5"
                    }
                  },
                  [
                    _c("b-card", { staticClass: "text-center info" }, [
                      _c("img", {
                        staticClass: "webmag-logo-big",
                        attrs: {
                          src: require("@/assets/logo-webmag-big.png"),
                          alt: "webmag logo"
                        }
                      }),
                      _c("h3", [_vm._v(_vm._s(_vm.$t("maintenance.title")))]),
                      _c("p", {
                        staticClass: "mb-2",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("maintenance.desc"))
                        }
                      })
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }