<template>
  <div id="content-wrapper">
    <div id="content">
      <b-container class="b-card-forms">
        <b-row class="vh-100" align-h="center">
          <b-col align-self="center" cols="12" sm="9" lg="5">
            <b-card class="text-center info">
              <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
              <h3>{{ $t('maintenance.title') }}</h3>
              <p class="mb-2" v-html="$t('maintenance.desc')"></p>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Auth from '@aws-amplify/auth';
import SetLanguageToBrowserLanguage from '@/mixins/setLanguageToBrowserLanguage';

export default {
  name: 'SomeThingWentWrong',
  mixins: [SetLanguageToBrowserLanguage],
  data() {
    return {
      problem: null,
      debugData: null,
      codeBlock: null,
    };
  },
  async created() {
    try {
      const userInfo = await Auth.currentUserInfo();
      if (!userInfo) {
        this.setLanguageToBrowserLanguage();
      }
    } catch (error) {
      this.setLanguageToBrowserLanguage();
    }
  },
};
</script>

<style scoped>
  h4 {
    font-size: 13px;
    font-weight: bold;
  }
</style>
